import React, { useCallback, useRef, useState } from "react";
import { AllGtmEvents } from "analytics/gtm";
import cx from "classnames";

import { AvailableFilter } from "api/requests/consumerApi/searchShopCoreMenuItems";
import { useEventTracker } from "hooks/useEventTracker";

import { InformationalModal } from "components/botanic/Modal";
import { MenuPage } from "components/MenuFilters/types/MenuFilters";
import { useFilterQueryParams } from "components/MenuFilters/v2/hooks";
import TrackImpression from "components/TrackImpression";

import { FilterButton } from "./FilterButton";
import { defaultRenderChips, Facet } from "./FilteredGrid";

export type MobileFilterShortcutsType = React.ComponentType<{
  onShortcutClick: (filterSectionKey?: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  availableFilters?: any;
  menuPage?: MenuPage;
  shallowRouting?: boolean;
  textColorClass?: string;
}>;

interface Props {
  confirmationButtonText: string;
  facets: Facet[];
  children?: React.ReactNode;
  shortcuts?: MobileFilterShortcutsType;
  onShortcutClick?: (filterKey?: string) => void;
  renderChips?: (facets: Facet[]) => React.ReactNode;
  filterPanelImpression?: AllGtmEvents;
  availableFilters?: AvailableFilter;
  onMobileFilterButtonClick?: () => void;
  mobileClassName?: string;
  menuPage?: MenuPage;
}

export const MobileFilters: React.FC<Props> = ({
  children,
  confirmationButtonText,
  facets,
  shortcuts: Shortcuts,
  onShortcutClick,
  availableFilters,
  filterPanelImpression,
  renderChips = defaultRenderChips,
  onMobileFilterButtonClick,
  mobileClassName,
  menuPage,
}) => {
  const { publishEvent } = useEventTracker();
  const [modalActive, setModalActive] = useState(false);
  const modalButtonRef = useRef(null);
  const {
    actions: { clearAllFilters },
  } = useFilterQueryParams();

  const numberOfFiltersSelected = facets.length;
  const filterTitle = `Filters${
    numberOfFiltersSelected ? ` (${numberOfFiltersSelected})` : ""
  }`;

  const openModal = useCallback((filterSectionKey?: string) => {
    if (onShortcutClick) {
      onShortcutClick(filterSectionKey);
    }
    setModalActive(true);
  }, []);

  return (
    <>
      <div
        className={cx(
          "flex items-center overflow-x-auto overflow-y-hidden whitespace-nowrap pb-sm mb-xs gap-1 no-scrollbar",
          mobileClassName,
        )}
      >
        {filterPanelImpression && (
          <TrackImpression
            trackingFunction={() => publishEvent(filterPanelImpression)}
          />
        )}
        <FilterButton
          filterCount={numberOfFiltersSelected}
          onClick={() => {
            openModal();
            if (onMobileFilterButtonClick) {
              onMobileFilterButtonClick();
            }
          }}
          ref={modalButtonRef}
        />
        {facets.length === 0 && Shortcuts && (
          <Shortcuts
            onShortcutClick={openModal}
            availableFilters={availableFilters}
            menuPage={menuPage}
          />
        )}
        {renderChips(facets)}
      </div>

      {modalActive && (
        <InformationalModal
          title={filterTitle}
          returnFocusRef={modalButtonRef}
          onDismissed={() => setModalActive(false)}
          expository
        >
          <div className="-mx-lg -mt-lg h-full flex flex-col">
            <div className="flex-1 overflow-auto">{children}</div>
            <div className="flex sticky bottom-0 w-full p-lg bg-white border-t border-light-grey items-center mt-auto">
              <button
                onClick={() => clearAllFilters()}
                className="button button--text flex-shrink-0 text-sm text-default"
              >
                clear all
              </button>
              <button
                className="button ml-auto w-[252px]"
                onClick={() => setModalActive(false)}
              >
                {confirmationButtonText}
              </button>
            </div>
          </div>
        </InformationalModal>
      )}
    </>
  );
};
